export enum NgHttpCachingHeaders {
  /**
   * Request is cacheable if HTTP method is into `allowedMethod`
   */
  ALLOW_CACHE = 'X-NG-HTTP-CACHING-ALLOW-CACHE',
  /**
   * Request isn't cacheable
   */
  DISALLOW_CACHE = 'X-NG-HTTP-CACHING-DISALLOW-CACHE',
  /**
   * Specific cache lifetime for the request
   */
  LIFETIME = 'X-NG-HTTP-CACHING-LIFETIME',
  /**
   * You can tag multiple request by adding this header with the same tag and
   * using `NgHttpCachingService.clearCacheByTag(tag: string)` for delete all the tagged request
   */
  TAG = 'X-NG-HTTP-CACHING-TAG'
}
