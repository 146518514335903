import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, Optional, NgModule } from '@angular/core';
import { Subscription, BehaviorSubject, fromEvent, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as i1 from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';

/**
 * InjectionToken for specifing ConnectionService options.
 */
const ConnectionServiceOptionsToken = new InjectionToken('ConnectionServiceOptionsToken');
const DEFAULT_CONNECTION_STATE = {
  hasInternetAccess: true,
  hasNetworkConnection: window.navigator.onLine
};
const DEFAULT_HEART_BEAT_INTERVAL = 1000;
// export const DEFAULT_HEART_BEAT_URL = 'https://jsonplaceholder.typicode.com';
const DEFAULT_HEART_BEAT_URL = 'http://localhost:3000';
const DEFAULT_HEART_BEAT_RETRY_INTERVAL = 1000;
var HTTP_REQUEST_METHODS;
(function (HTTP_REQUEST_METHODS) {
  HTTP_REQUEST_METHODS["HEAD"] = "head";
  HTTP_REQUEST_METHODS["GET"] = "get";
  HTTP_REQUEST_METHODS["POST"] = "post";
  HTTP_REQUEST_METHODS["PUT"] = "put";
  HTTP_REQUEST_METHODS["OPTIONS"] = "options";
})(HTTP_REQUEST_METHODS || (HTTP_REQUEST_METHODS = {}));
const DEFAULT_OPTIONS = {
  enableHeartbeat: false,
  heartbeatUrl: DEFAULT_HEART_BEAT_URL,
  heartbeatInterval: DEFAULT_HEART_BEAT_INTERVAL,
  heartbeatRetryInterval: 1000,
  requestMethod: HTTP_REQUEST_METHODS.HEAD
};
class ConnectionService {
  http;
  currentState = DEFAULT_CONNECTION_STATE;
  serviceOptions = DEFAULT_OPTIONS;
  subscription = new Subscription();
  httpSubscription = new Subscription();
  stateChanged$ = new BehaviorSubject(DEFAULT_CONNECTION_STATE);
  constructor(http, options) {
    this.http = http;
    // TODO: Token useValue in providers not working.
    this.serviceOptions = {
      ...DEFAULT_OPTIONS,
      ...options
    };
    this.checkNetworkState();
    if (this.serviceOptions.enableHeartbeat) {
      this.checkInternetState();
    }
  }
  checkNetworkState() {
    this.subscription.add(fromEvent(window, 'online').subscribe(() => {
      this.currentState.hasNetworkConnection = true;
      this.checkInternetState();
      this.publishState();
    }));
    this.subscription.add(fromEvent(window, 'offline').subscribe(() => {
      this.currentState.hasNetworkConnection = false;
      this.checkInternetState();
      this.publishState();
    }));
  }
  checkInternetState() {
    if (this.serviceOptions.enableHeartbeat) {
      this.subscription = interval(3000).pipe(switchMap(async () => this.http[this.serviceOptions.requestMethod || HTTP_REQUEST_METHODS.HEAD](this.serviceOptions.heartbeatUrl || DEFAULT_HEART_BEAT_URL, {
        responseType: 'text'
      }).subscribe({
        next: data => {
          this.currentState.hasInternetAccess = true;
          this.publishState();
        },
        error: err => {
          this.currentState.hasInternetAccess = false;
          this.publishState();
          throw err;
        }
      }))).subscribe(res => {});
      // this.httpSubscription = timer(0, this.serviceOptions.heartbeatInterval || DEFAULT_HEART_BEAT_INTERVAL)
      //   .pipe(
      //     switchMap(async () => this.http[this.serviceOptions.requestMethod || HTTP_REQUEST_METHODS.HEAD](this.serviceOptions.heartbeatUrl || DEFAULT_HEART_BEAT_URL,
      //       { responseType: 'text' })),
      //     retryWhen(errors =>
      //       errors.pipe(
      //         // log error message
      //         tap(val => {
      //           this.currentState.hasInternetAccess = false;
      //           this.publishState();
      //           throw errors;
      //         }),
      //         // restart after 5 seconds
      //         delay(this.serviceOptions.heartbeatRetryInterval || DEFAULT_HEART_BEAT_RETRY_INTERVAL)
      //       )
      //     )
      //   )
      //   .subscribe(result => {
      //     this.currentState.hasInternetAccess = true;
      //     this.publishState();
      //   });
    } else {
      this.currentState.hasInternetAccess = false;
      this.publishState();
    }
  }
  publishState() {
    this.stateChanged$.next(this.currentState);
  }
  /**
  * Monitor Network & Internet connection status by subscribing to this observer. If you set "reportCurrentState" to "false" then
  * function will not report current status of the connections when initially subscribed.
  * @param reportCurrentState Report current state when initial subscription. Default is "true"
  */
  monitor(options) {
    if (options) {
      this.serviceOptions = {
        ...this.serviceOptions,
        ...options
      };
    }
    if (this.serviceOptions.enableHeartbeat) {
      this.checkInternetState();
    }
    return this.stateChanged$;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  static ɵfac = function ConnectionService_Factory(t) {
    return new (t || ConnectionService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(ConnectionServiceOptionsToken, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ConnectionService,
    factory: ConnectionService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConnectionService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1.HttpClient
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [ConnectionServiceOptionsToken]
      }, {
        type: Optional
      }]
    }];
  }, null);
})();
class ConnectionServiceModule {
  static ɵfac = function ConnectionServiceModule_Factory(t) {
    return new (t || ConnectionServiceModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ConnectionServiceModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [ConnectionService],
    imports: [HttpClientModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConnectionServiceModule, [{
    type: NgModule,
    args: [{
      imports: [HttpClientModule],
      providers: [ConnectionService]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ng-connection-service
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ConnectionService, ConnectionServiceModule, ConnectionServiceOptionsToken, DEFAULT_CONNECTION_STATE, DEFAULT_HEART_BEAT_INTERVAL, DEFAULT_HEART_BEAT_RETRY_INTERVAL, DEFAULT_HEART_BEAT_URL, DEFAULT_OPTIONS, HTTP_REQUEST_METHODS };
