import {inject, Injectable} from '@angular/core';
import {ApiClientOptions, PostResponse} from '../../../../../../src/app/core/models';
import {HttpAction} from '../../../../../../src/app/core/enums';
import {ApiService} from './api.service';
import {UpdateUserModel} from '../models/update-user-model';
import {ClassLiteResponseModel} from '../models/class-lite-response-model';
import {SchoolLiteResponseModel} from '../models/school-lite-response-model';
import {IUniversalScreenerStatusModel} from '../models/universal-screener-model';
import {HttpContext} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiService = inject(ApiService);

  constructor() { }

  updateUser(userId: number, request: UpdateUserModel) {
    const url = `v1/internal/users/${userId}`;
    return this.apiService.invokeMPApi<PostResponse>(url, HttpAction.Put, request);
  }

  updatePassword(userId: number, currentPassword: string, newPassword: string) {
    const url = `v1/internal/users/${userId}/password`;
    return this.apiService.invokeMPApi<PostResponse>(url, HttpAction.Put, {currentPassword, newPassword});
  }

  getUniversalScreenerStatus(userId: number) {
    const url = `v1/internal/students/${userId}/UniversalScreenerStatus`;
    return this.apiService.invokeMPApi<IUniversalScreenerStatusModel>(url, HttpAction.Get);
  }

  getSchoolsLiteByCustomerId(customerId: number) {
    const url = `v1/internal/districts/${customerId}/schoolslite`;
    return this.apiService.invokeMPApi<SchoolLiteResponseModel[]>(url, HttpAction.Get);
  }

  getClassesBySchoolIds(schoolIds: number[]) {
    const url = `v1/internal/classes/GetClassesBySchoolIds`;
    return this.apiService.invokeMPApi<ClassLiteResponseModel[]>(url, HttpAction.PostWithBodyResponse, schoolIds);
  }

  getClassesByClassIds(classIds: number[], context: HttpContext = null) {
    const url = `v1/internal/classes/GetClassesByClassIds`;
    const options = new ApiClientOptions();
    options.httpContext = context;
    return this.apiService.invokeMPApi<ClassLiteResponseModel[]>(url, HttpAction.PostWithBodyResponse, classIds, options);
  }
}
