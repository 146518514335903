import {inject, Injectable} from '@angular/core';
import {ConnectionService, ConnectionState} from 'ng-connection-service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Subject} from 'rxjs';
import {AppStateConstants} from '../../../../../../src/app/globals';
import {AppStateService} from '../../../../../../src/app/app.service';

@Injectable({
  providedIn: 'root'
})
export class NetworkConnectionService {
  private connectionService = inject(ConnectionService);
  private appStateService = inject(AppStateService);
  connectionState: ConnectionState;
  private networkChangeSubject = new Subject<ConnectionState>();
  networkChange$ = this.networkChangeSubject.asObservable();

  constructor() {
    this.connectionService.monitor()
      .pipe(
        takeUntilDestroyed()
      )
      .subscribe(res => {
        if (this.connectionState?.hasNetworkConnection !== res.hasNetworkConnection ||
          this.connectionState?.hasInternetAccess !== res.hasInternetAccess) {
          this.connectionState = {...res};
          this.notifyNetworkChange();
        }
      })
  }

  notifyNetworkChange() {
    // Only setting AppStateConstants.online to false when user system loosed network, setting it true is part of login workflow
    if (!this.connectionState.hasNetworkConnection) {
      this.appStateService.set(AppStateConstants.online, false);
    }
    this.networkChangeSubject.next(this.connectionState);
  }
}
